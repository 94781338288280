import {
    ActiveIncidentsCountDTO,
    ActivityLevelDTO,
    BloodGlucoseDTO,
    BloodOxygenDTO,
    BloodPressureDTO,
    BodyPositionDTO,
    BodyTemperatureDTO,
    BodyWeightDTO,
    ClinicalDocumentDTO,
    ConsumableDeviceHealthDTO,
    CoughDTO,
    DefaultGraphValueResponse,
    DegreeOfInclineWhileSleepingDTO,
    GraphBioIntelliSenseDoubleResponse,
    GraphBioIntelliSenseIntResponse,
    GraphBloodPressureResponse,
    GraphDoubleValueResponse,
    GraphIntValueResponse,
    GraphOrthostaticBloodPressureResponse,
    HeartRateAtRestDTO,
    HeartRateDTO,
    HourlyGaitDTO,
    MeasurementTypeDTO,
    MediaDTO,
    MediaStatusHistoryDTO,
    MetricHistoryResponseDTO,
    MetricThresholdDetailedDTO,
    MetricThresholdEditHighRequest,
    MetricThresholdEditLowRequest,
    MetricTypeDTO,
    ObservationHintDTO,
    ObservationTypes,
    ObservationWithThresholdDTO,
    OnOffBodyDTO,
    OrthostaticBloodPressureDTO,
    OxygenSaturationDTO,
    PageClinicalDocumentDTO,
    PageMediaDTO,
    PageObservationDTO,
    PainScaleDTO,
    PerfusionIndexDTO,
    RecentMetricTypeDTO,
    RecentPatientMetricCountDTO,
    RecentPatientMetricDTO,
    RespiratoryRateAtRestDTO,
    RespiratoryRateDTO,
    SkinTemperatureDTO,
    SleepDTO,
    TagResponseDTO,
} from '@/types/observationsModels';

import urls from '../constants/endpoints/observations';
import httpApi from './httpApi_new';

class ObservationService {
    getAllMetrics = (
        patientId: number,
        queryParams?: {observationTypes: string[]}
    ): Promise<ObservationWithThresholdDTO[]> =>
        httpApi.get({
            url: urls.OBSERVATION_METRIC_LIST(patientId),
            queryParams,
        });

    getRecentMetrics = (patientId: string, queryParams?: {types: string}): Promise<RecentPatientMetricDTO[]> =>
        httpApi.get({
            url: urls.GET_RECENT_METRICS(patientId),
            queryParams,
        });

    addMoreMediaTags = ({
        tagCodes,
        mediaId,
        missionControlId,
    }: {
        tagCodes: string[];
        mediaId: string;
        missionControlId: number;
    }): Promise<unknown> =>
        httpApi.patch({
            data: {tagCodes, missionControlId},
            url: urls.MEDIA_ADD_MORE_TAGS(+mediaId),
        });

    getCurrentMetricDevices = (
        patientId: number,
        queryParams: {
            type?: string;
            fromDate?: string;
            toDate?: string;
            page?: number;
            size?: number;
            sort?: string[];
        }
    ): Promise<PageObservationDTO> =>
        httpApi.get({
            url: urls.PATIENTS_OBSERVATIONS(patientId),
            queryParams,
        });

    //TODO merge with (getCurrentMetricDevices)
    getVitalSignsExtended = ({
        id,
        first,
        after,
        order,
        fromDate,
        toDate,
        types,
    }: {
        id: number;
        first?: number;
        after?: string;
        types?: string[];
        fromDate?: string;
        toDate?: string;
        order?: 'ASC' | 'DESC';
    }): Promise<PageObservationDTO> =>
        httpApi.get({
            url: urls.PATIENTS_OBSERVATIONS(id),
            queryParams: {first, after, order, fromDate, toDate, types},
        });

    getTypesList = (): Promise<MetricTypeDTO[]> =>
        httpApi.get({
            url: urls.OBSERVATIONS_TYPES,
        });

    getMediaList = ({
        id,
        page,
        size,
        tagCodes,
        sort,
    }: {
        id: number;
        page: number;
        size: number;
        tagCodes?: string[];
        sort: string;
    }): Promise<PageMediaDTO> => {
        const queryParams = {
            ...(page ? {page} : {}),
            ...(size ? {size} : {}),
            ...(tagCodes ? {tagCodes} : {}),
            sort,
        };
        return httpApi.get({
            url: urls.MEDIA(id),
            queryParams,
        });
    };

    getMediaFullSize = ({id}: {id: number}): Promise<MediaDTO> =>
        httpApi.get({
            url: urls.MEDIA_FULL_SIZE(id),
        });

    getMediaTags = (): Promise<TagResponseDTO[]> =>
        httpApi.get({
            url: urls.MEDIA_TAGS_GET_ALL,
        });

    removeMediaTag = ({
        tagCode,
        mediaId,
        missionControlId,
    }: {
        tagCode: string;
        mediaId: string;
        missionControlId: number;
    }): Promise<unknown> =>
        httpApi.patch({
            url: urls.MEDIA_TAG_REMOVE(+mediaId),
            data: {tagCode, missionControlId},
        });

    getMediaStatuses = (): Promise<string[]> =>
        httpApi.get({
            url: urls.MEDIA_STATUSES,
        });

    getClinicalDocumentsList = ({
        id
    }: {
        id: number;
    }): Promise<PageClinicalDocumentDTO> => {
        return httpApi.get({
            url: urls.CLINICAL_DOCUMENTS(id)
        });
    };

    getClinicalDocument = ({patientId, documentId}: {patientId: number, documentId: string}): Promise<ClinicalDocumentDTO> => {
        return httpApi.get({
            url: urls.CLINICAL_DOCUMENT(patientId, documentId)
        });
    };

    getMonitoringDevices = (patientId: number): Promise<ConsumableDeviceHealthDTO[]> =>
        httpApi.get({url: urls.GET_MONITORING_DEVICES(patientId)});

    getStatusHistory = (mediaId: string): Promise<MediaStatusHistoryDTO[]> =>
        httpApi.get({
            url: urls.MEDIA_STATUS_HISTORY(+mediaId),
        });

    updateMediaStatus = ({mediaId, status}: {mediaId: string; status: string}): Promise<unknown> =>
        httpApi.patch({
            url: urls.MEDIA_UPDATE_STATUS(+mediaId),
            data: {
                label: status,
            },
        });

    deleteMedia = ({id}: {id: string}): Promise<{[key: string]: number}> =>
        httpApi.delete({
            url: urls.MEDIA_DELETE(+id),
        });

    sendEHR = ({
        observationId,
        patientId,
    }: {
        observationId: number;
        patientId: number;
    }): Promise<
        | ActivityLevelDTO
        | BloodGlucoseDTO
        | BloodPressureDTO
        | BodyPositionDTO
        | BodyTemperatureDTO
        | BodyWeightDTO
        | CoughDTO
        | DegreeOfInclineWhileSleepingDTO
        | HeartRateAtRestDTO
        | HeartRateDTO
        | HourlyGaitDTO
        | OnOffBodyDTO
        | OrthostaticBloodPressureDTO
        | OxygenSaturationDTO
        | PainScaleDTO
        | PerfusionIndexDTO
        | RespiratoryRateAtRestDTO
        | RespiratoryRateDTO
        | SkinTemperatureDTO
        | SleepDTO
    > =>
        httpApi.post({
            url: urls.PUBLISH_OBSERVATION(observationId, patientId),
        });

    addVitalSign = ({
        patientId,
        data,
    }: {
        patientId: number;
        data:
            | BloodGlucoseDTO
            | BloodOxygenDTO
            | BloodPressureDTO
            | BodyTemperatureDTO
            | BodyWeightDTO
            | HeartRateAtRestDTO
            | HeartRateDTO
            | OrthostaticBloodPressureDTO
            | PainScaleDTO
            | PerfusionIndexDTO
            | RespiratoryRateAtRestDTO
            | RespiratoryRateDTO
            | SkinTemperatureDTO;
    }): Promise<string> =>
        httpApi.post({
            url: urls.OBSERVATIONS(patientId),
            data,
        });

    getMeasurementUnits = (): Promise<MeasurementTypeDTO[]> => httpApi.get({url: urls.OBSERVATIONS_MEASUREMENT_TYPES});

    getMetricsCount = ({
        patientId,
        queryParams,
    }: {
        patientId: number;
        queryParams?: {types: string};
    }): Promise<RecentPatientMetricCountDTO> =>
        httpApi.get({
            url: urls.OBSERVATIONS_COUNT(patientId),
            queryParams,
        });

    getMetricHint = ({
        patientId,
        queryParams,
    }: {
        patientId: string;
        queryParams: {metricType: RecentMetricTypeDTO};
    }): Promise<ObservationHintDTO> => httpApi.get({url: urls.GET_METRIC_HINT(+patientId), queryParams});

    getMetricGraph = (
        patientId: number,
        queryParams: {dateFrom: string; dateTo: string; observationType: string}
    ): Promise<
        (
            | DefaultGraphValueResponse
            | GraphBioIntelliSenseDoubleResponse
            | GraphBioIntelliSenseIntResponse
            | GraphBloodPressureResponse
            | GraphDoubleValueResponse
            | GraphIntValueResponse
            | GraphOrthostaticBloodPressureResponse
        )[]
    > => httpApi.get({url: urls.OBSERVATION_METRIC_GRAPH(patientId), queryParams});

    getPatientThresholds = (
        patientId: number,
        queryParams: {
            observationType: ObservationTypes;
        }
    ): Promise<MetricThresholdDetailedDTO[]> => httpApi.get({url: urls.GET_PATIENT_THRESHOLDS(patientId), queryParams});

    getPatientDefaultThresholds = (queryParams: {observationType: string}): Promise<MetricThresholdDetailedDTO[]> =>
        httpApi.get({url: urls.GET_PATIENT_DEFAULT_THRESHOLDS, queryParams});

    thresholdEditLow = ({
        patientId,
        data,
    }: {
        patientId: number;
        data: MetricThresholdEditLowRequest;
    }): Promise<unknown> =>
        httpApi.patch({
            url: urls.THRESHOLD_EDIT_LOW(patientId),
            data,
        });

    thresholdEditHigh = ({
        patientId,
        data,
    }: {
        patientId: number;
        data: MetricThresholdEditHighRequest;
    }): Promise<unknown> =>
        httpApi.patch({
            url: urls.THRESHOLD_EDIT_HIGH(patientId),
            data,
        });

    thresholdsResetToDefault = ({
        patientId,
        queryParams,
    }: {
        patientId: number;
        queryParams: {observationType: string};
    }): Promise<unknown> =>
        httpApi.patch({
            url: urls.THRESHOLD_RESET_TO_DEFAULT(patientId),
            queryParams,
        });

    getAlertCounter = (queryParams: {subjectId: number}): Promise<ActiveIncidentsCountDTO> =>
        httpApi.get({url: urls.GET_ALERT_COUNTER, queryParams});

    getMetricsHistory = (queryParams: {
        patientId: number;
        observationType: string;
    }): Promise<MetricHistoryResponseDTO> => httpApi.get({url: urls.METRICS_HISTORY, queryParams});
}

export const observationService = new ObservationService();
