const OBSERVATIONS = {
    OBSERVATIONS: (patientId: number) => `/observations/v1/observations/${patientId}` as const,
    OBSERVATIONS_MEASUREMENT_TYPES: '/observations/v1/observations/measurement-types' as const,
    MEASUREMENT_UNITS: '/observations/v1/observations/metric/measurement-units' as const,
    OBSERVATIONS_TYPES: '/observations/v1/observations/metric/metric-types' as const,
    GET_URGENT_ALERT_COUNT: '/observations/v1/patient-incidents/count' as const,
    OBSERVATIONS_COUNT: (patientId: number) =>
        `/observations/v1/observations/metric/${patientId}/metric-count` as const,
    PATIENTS_OBSERVATIONS: (id: number) => `/observations/v1/observations/${id}` as const,
    PUBLISH_OBSERVATION: (observationId: number, patientId: number) =>
        `/observations/v1/observations/${patientId}/publish/${observationId}` as const,
    HAS_THRESHOLD_TRIGGERED_OBSERVATIONS: (patientId: number) =>
        `/observations/v1/observations/${patientId}/has-threshold-triggered` as const,
    OBSERVATION_METRIC_LIST: (patientId: number) =>
        `/observations/v1/observations/metric/patient/${patientId}/last-observations` as const,
    GET_RECENT_METRICS: (patientId: string) =>
        `/observations/v1/observations/metric/${patientId}/recent-metrics` as const,
    GET_METRIC_HINT: (patientId: number) =>
        `/observations/v1/observations/metric/patient/${patientId}/observation-hint` as const,
    OBSERVATION_METRIC_GRAPH: (patientId: number) =>
        `/observations/v1/observations/metric/patient/${patientId}/graph` as const,

    MEDIA: (id: number) => `/observations/v1/media/${id}/media` as const,
    MEDIA_DELETE: (id: number) => `/observations/v1/media/${id}` as const,
    MEDIA_FULL_SIZE: (id: number) => `/observations/v1/media/${id}/media/fullsize` as const,
    MEDIA_TAGS_GET_ALL: '/observations/v1/tags' as const,
    MEDIA_TAG_REMOVE: (mediaId: number) => `/observations/v1/media/${mediaId}/remove-tag` as const,
    MEDIA_ADD_MORE_TAGS: (mediaId: number) => `/observations/v1/media/${mediaId}/add-tags` as const,
    MEDIA_STATUS_HISTORY: (mediaId: number) => `/observations/v1/media/${mediaId}/status-history` as const,
    MEDIA_UPDATE_STATUS: (mediaId: number) => `/observations/v1/media/${mediaId}/update_status` as const,
    MEDIA_STATUSES: '/observations/v1/media/statuses' as const,

    CLINICAL_DOCUMENTS: (patientId: number) => `/observations/v1/patients/${patientId}/clinical-documents` as const,
    CLINICAL_DOCUMENT: (patientId: number, documentId: string) => `/observations//v1/patients/${patientId}/clinical-documents/${documentId}` as const,

    GET_MONITORING_DEVICES: (patientId: number) => `/observations/v1/consumable/${patientId}/device-health` as const,

    GET_PATIENT_THRESHOLDS: (patientId: number) => `/observations/v1/metrics/thresholds/patient/${patientId}` as const,
    GET_PATIENT_DEFAULT_THRESHOLDS: '/observations/v1/metrics/thresholds/default' as const,
    THRESHOLD_EDIT_LOW: (patientId: number) =>
        `/observations/v1/metrics/thresholds/patient/${patientId}/edit/low` as const,
    THRESHOLD_EDIT_HIGH: (patientId: number) =>
        `/observations/v1/metrics/thresholds/patient/${patientId}/edit/high` as const,
    THRESHOLD_RESET_TO_DEFAULT: (patientId: number) =>
        `/observations/v1/metrics/thresholds/patient/${patientId}/reset-to-default` as const,

    OPEN_INCIDENTS: '/observations/v1/incidents/active' as const,
    ALL_INCIDENTS: '/observations/v1/incidents' as const,
    PATIENT_INCIDENTS: '/observations/v1/patient-incidents' as const,
    INCIDENT_ACKNOWLEDGE: (incidentId: number) => `/observations/v1/incidents/${incidentId}/acknowledge` as const,
    ALARMS_STATUS_FILTER_VALUES: '/observations/v1/incidents/status-filter-values',
    INCIDENT_HISTORY: (incidentId: number) => `/observations/v1/incidents/${incidentId}/history` as const,
    GET_ALERT: (alarmId: number) => `/observations/v1/alarms/${alarmId}` as const,
    GET_PATIENT_THRESHOLD_ALERTS: '/observations/v1/alarms/by-type' as const,
    ENABLE_ALERT: (alarmId: number) => `/observations/v1/alarms/${alarmId}/enable` as const,
    DISABLE_ALERT: (alarmId: number) => `/observations/v1/alarms/${alarmId}/disable` as const,
    ALARMS_THRESHOLD_TYPES: '/observations/v1/alarms/threshold-types',
    INCIDENTS_CONFIGURATION: '/observations/v1/alarms' as const,
    CREATE_INCIDENT_TEMPLATE: '/observations/v1/alarm-templates' as const,
    EDIT_INCIDENT_TEMPLATE: (templateId: number) => `/observations/v1/alarm-templates/${templateId}` as const,
    GET_ALERT_COUNTER: '/observations/v1/incidents/active/count' as const,
    GET_TECH_TYPES: '/observations/v1/alarms/technical-types' as const,

    METRICS_HISTORY: '/observations/v1/metrics/history' as const,
};

export default OBSERVATIONS;
